import React, { useState } from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"
import { animateScroll as scroll } from 'react-scroll'

import DownloadButton from "components/DownloadButton"
import Button from "components/Button"
import Container from "components/container"
import Layout from "components/layout"
import ResourcePageHeader from "templates/landing-campaign/resourcePageHeader"
import { getHexColor } from "theme/colors"
import fluidValues from "utilities/fluidValues"
import { animated, useTransition } from 'react-spring'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

export default connectFela(({data: { sanitySlidesPage: { color }}}) => ({
  intro: {
    paddingBottom: "0 !important",
  },
  container: {
    position: 'relative',
    borderBottom: '1px solid black'
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    opacity: 0,
    transition: 'opacity 250ms ease-out',

    onHover: {
      opacity: 1,
    },
    '@media (hover: none)': {
      backgroundColor: "transparent",
      opacity: 1,
      alignItems: 'end',
      justifyContent: 'end'
    },
  },
  button: {
    backgroundColor: 'white',
    '@media (hover: none)': {
      display: 'none'
    }
  },
  loadmoreButton: {
    backgroundColor: 'white',
  },
  mobileButton: {
    position: 'absolute',
    opacity: 0,
    '@media (hover: none)': {
      opacity: 1,
      marginRight: 10,
      marginBottom: 10,
    },
  },
  slidersContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    zIndex: 1,
    ...fluidValues({
      columnGap: [5, 42],
      rowGap: [32, 60],
      marginTop: [60, 120],
      marginBottom: [60, 120],
    }),

    phoneOnly: {
      gridGap: '32px !important',
      gridTemplateColumns: "100%",
    }
  },
  loadmoreContainer: {
    textAlign: 'center',
    ...fluidValues({
      marginBottom: [60, 120]
    })
  }
}))(({
  pageContext,
  path,
  data: {
    jobs,
    settings,
    sanitySlidesPage: {
      title,
      meta,
      slug,
      intro,
      color,
      circleColor,
      slides
    }
  }, rules, styles
}) => {
  const [p, setP] = useState(slides.slice(0, 14))
  const transitions = useTransition(
    p,
    {
      config: {delay: 0.9, tension: 200},
      from: { opacity: 0, transform: 'scale(0.4)' },
      enter: { opacity: 1, transform: 'scale(1)' },
      leave: { opacity: 1, transform: 'scale(1)' },
    })

  const loadMore = (e) => {
    e.preventDefault();
    setP((p) => slides.slice(0, p.length + 4))
    scroll.scrollMore(300);
  }

  return (
    <Layout
      pageColor={getHexColor(color, '500')}
      linkColor={getHexColor(color, '200')}
      title={title}
      slug={{ current: path }}
      meta={meta || {}}
      settings={settings}
      jobs={jobs}
      country={pageContext.country}
    >

      <ResourcePageHeader
        title={title}
        intro={intro}
        extend={{introContainer: rules.intro}}
        pageColor={circleColor ? getHexColor(circleColor, '') : getHexColor(color)} />

      <Container withContentArea5 extend={{styles: rules.slidersContainer}}>
        {transitions((props, slide) => (
          <animated.div style={props} className={styles.container}>
            <Img className={styles.image} image={slide.asset.gatsbyImageData} alt="slides"/>
            <div className={styles.overlay}>
              <Button
                extend={{styles: rules.button}}
                download={slide.asset.url}
                to={slide.asset.url + "?dl=" + slide.asset.filename}>Download</Button>
              <DownloadButton
                target="_blank"
                download={slide.asset.url}
                extend={{styles: rules.mobileButton}}
                revert={slide.dominantTone === "Dark"}
                to={slide.asset.url + "?dl=" + slide.asset.filename} />
            </div>
          </animated.div>
        ))}
      </Container>

      <Container withContentArea5 extend={{styles: rules.loadmoreContainer}}>
        {p.length < slides.length &&
         <Button
           extend={{styles: rules.loadmoreButton}}
           onClick={loadMore}
           to={""}
         >Load more</Button>}
      </Container>
    </Layout>
  )
})

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanitySlidesPage {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      circleColor
      color
      intro
      slides {
          dominantTone
          asset {
            url
            filename
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
        }
      }
    }
  }
`
