/**
 * @fileOverview A element styled as Button
 * @name Button.js
 */
import React from "react"
import { connect as connectFela } from "react-fela"

import fluidDimensions from "utilities/fluidDimensions"
import colors from "theme/colors"
import Link from "components/BaseLink"

/**
 * P
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled A
 */
const downloadButton = ({
  styles,
  rules,
  children,
  to,
  onClick,
  download,
}) => {
  return (
    <Link
      onClick={onClick}
      download={download}
      alt={"Download"}
      to={to}
      extend={{ styles: rules.styles }}
    >
      <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="29.5" stroke="#302739" />
        <path
          d="M30.8662 33.1364C30.4813 33.803 29.5191 33.803 29.1342 33.1364L24.9615 25.9091C24.5766 25.2424 25.0577 24.4091 25.8275 24.4091L34.1728 24.4091C34.9426 24.4091 35.4238 25.2424 35.0389 25.9091L30.8662 33.1364Z"
          fill="#302739"
        />
        <line
          x1="35.5"
          y1="40.5"
          x2="24.5"
          y2="40.5"
          stroke="#302739"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Link>
  )
}

/* Styles */
const styles = props => ({
  cursor: "pointer",
  ...fluidDimensions({ width: [36, 60], height: [36, 60] }),
  "& svg": {
    width: "100%",
    transition: "fill 0.2s ease",
    fill: "transparent",

    "& circle": {
      stroke: props.revert ? "white" : colors.colorConsole500,
    },
    "& line": {
      stroke: props.revert ? "white" : colors.colorConsole500,
    },
    "& path": {
      fill: props.revert ? "white" : colors.colorConsole500,
    },
  },
  onHover: {
    "& svg": {
      "& path": {
        fill: props.revert ? "black" : "transparent",
      },
      transition: "fill 0.2s ease",
      stroke: props.revert ? colors.colorConsole500 : "white",
      fill: props.revert ? "#FFFFFF" : "#000000",

      "& path": {
        fill: props.revert ? "#000000" : "#FFFFFF",
      },
      "& line": {
        stroke: props.revert ? "#000000" : "#FFFFFF",
      },
      "& circle": {
        stroke: props.revert ? "#000000" : "#FFFFFF",
      },
    },
  },
})

/* Component */
const DownloadButton = connectFela({ styles })(downloadButton)
export default DownloadButton
