/**
 * @fileOverview Header For the resource pages
 */

import React from "react"
import { connect as connectFela } from "react-fela"

import Breadcrumb from 'components/Breadcrumb'
import Container from "components/container"
import P from "components/typography/P"
import UnderlinedTitle from "components/UnderlinedTitle"
import fluidValues from "utilities/fluidValues"

/* JSX */
const resourcePageHeader = ({rules, styles, title, intro, breadcrumbLeft, breadcrumbRight, pageColor, headerImage}) => {
    return <Container withContentArea5 extend={{styles: rules.introContainer}}>
        <div className={styles.introCircle} />
        {(breadcrumbLeft || breadcrumbLeft) && <Breadcrumb leftPart={breadcrumbLeft} rightPart={breadcrumbRight} extend={{styles: rules.breadcrumb}} />}
        <div className={styles.title}><UnderlinedTitle style='hero'>{title}</UnderlinedTitle></div>
        <P version='large' extend={{styles: rules.intro}}>{intro}</P>
    </Container> }

/* Styles */
const styles = (props) => ({
    introContainer: {
        ...fluidValues({
            paddingTop: [133, 184],
            paddingBottom: [60, 200]
        }),
        zIndex: 1,

        phoneOnly: {
            position: 'relative'
        },
    },
    intro: {
        maxWidth: 514,
        marginRight: 18,
        ...fluidValues({marginLeft: [45, 112]}),
        extend: {
            condition: props.headerImage != null && props.annoying,
            style: {
                marginTop: 40,
                phoneOnly: {
                    ...fluidValues({
                        marginTop: [130, 800]
                    })
                }
            }
        }
    },
    title: {
      marginBottom: 16
        // ...fluidValues({marginBottom: [16, 72]}),
    },
    introCircle: {
        extend: [{
            condition: props.headerImage != null,
            style: {
                backgroundImage: 'url('  + props.headerImage?.asset.gatsbyImageData.images.fallback.src + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                maxWidth: 1152,
                maxHeight: 1152,
            }
        }, {
            condition: props.headerImage != null && props.annoying,
            style: {
                backgroundImage: 'url('  + props.headerImage?.asset.gatsbyImageData.images.fallback.src + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                maxWidth: 1152,
                maxHeight: 1152,
            }
        }, {
            condition: !props.headerImage,
            style: {
                backgroundColor: props.pageColor
            }
        }],
        borderRadius: '50%',
        left: 0,
        margin: '0 auto',
        position: 'absolute',
        right: 0,
        // top: '-24vw',
        top: '29vw',
        zIndex: -1,
        height: '80vw',
        width: '80vw',

      tabletPortraitAndUp: {
        // height: '80vw',
        // width: '80vw',
        top: -150,
      },

      '@media(min-width: 1200px)': {
        height: '57vw',
        width: '57vw',
        // top: -150,
      }
    },
})

/* Component */
export default connectFela(styles)(resourcePageHeader)
